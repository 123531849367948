import { BackendApi, WttRestResponseBody } from '../backend-api';
import { isApiResponse } from '../api';
import { Feature, FeatureResponse } from './feature-response';

export class FeatureApi extends BackendApi {
  readonly BASE_PATH = '/api/v1/other/features';

  async getFeatures(): Promise<FeatureResponse> {
    const response = await this.mapGeneralErrors<FeatureResponse>(
      this.fetch('GET', this.BASE_PATH)
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<Feature[]>;

    return {
      status: 'OK',
      features: responseBody.data
    };
  }
}

export const featuresApi = new FeatureApi();
